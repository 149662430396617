@import "../../mixins.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
    max-width: 0;
  }
  to {
    opacity: 1;
    max-width: 700px;
  }
}

.privacy-policy-popup {
  animation: fadeIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 100%;
  max-width: 1200px;
  height: 80%;
  z-index: 9999;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  background: #111;
  border-radius: 12px;
  box-shadow: 0 0 60px 5px #000000;
  background: linear-gradient(#000000c9, #000000c9);
  background-size: cover;
  @include flex;

  .icon-box {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    right: -17px;
    // right: -45px;
    top: -17px;
    // top: -45px;
    z-index: 9999;
    @include flex;
    border: 1px solid var(--primary-color);
    transition: var(--basic);
    cursor: pointer;
    .icon {
      font-size: 32px;
      color: var(--primary-color);
      transition: var(--advance);
    }

    &:hover {
      // transform: scale(1.1);
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  @media(max-width: 750px){
    width: 90%;
  }
}
