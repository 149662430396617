@import "../../mixins.scss";

.footer {
  width: 100%;
  padding: 80px 10% 36px 10%;
  position: relative;
  bottom: 0;
  background: url("../../assets/images/footer-bg.webp") top center no-repeat;
  background-size: cover;

  .main-footer {
    @include flex($ai: flex-start);

    .company {
      flex: 1.2;
      margin-top: -30px;
      img[alt="logo"] {
        width: 170px;
        height: auto;
      }
      p {
        @include para();
        color: var(--primary-text);
        margin: 10px 0 15px 0;
        line-height: 29px;
      }
      .social-links {
        @include flex($jc: flex-start, $ai: flex-start);
        li {
          @include flex();
          a {
            margin: 0 7px;
            padding: 22px 10px;
            height: 30px;
            background: #fff;
            border-radius: 100%;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            @include flex();
            cursor: pointer;
            &:first-child {
              margin-left: 0;
            }
            img {
              width: 25px;
              object-fit: cover;
              height: auto;
            }
          }
        }
      }
    }

    .quick-links {
      flex: 1;
      padding-left: 60px;
      padding-top: 17px;

      h3 {
        @include heading2();
        color: var(--primary-text);
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
      ul {
        li {
          margin: 7px 0;
          @include flex($jc: flex-start);
          a {
            @include para();
            color: var(--primary-text);
          }
        }
      }
    }

    .working-hours {
      flex: 1;
      padding-top: 17px;
      h3 {
        @include heading2();
        color: var(--primary-text);
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
      p {
        @include para();
        color: var(--primary-text);
        margin: 7px 0;
      }
      button {
        margin-top: 20px;
        @include button($w: 200px);
        span {
          @include para($fw: 400);
          font-size: 1.1rem;
        }
      }
    }

    .contact-us {
      flex: 1.4;
      padding-left: 45px;
      padding-top: 17px;
      h3 {
        @include heading2();
        color: var(--primary-text);
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
      a,
      div {
        @include flex($jc: flex-start, $ai: flex-start);
        margin: 20px 0;
        img {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        p,
        a {
          @include para();
          color: var(--primary-text);
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding: 30px 5% 60px 5%;

    .main-footer {
      flex-direction: column;
      .company,
      .quick-links,
      .contact-us,
      .working-hours {
        margin-top: 40px;
        padding: 0;
      }
    }
  }
}

.copyright {
  @include flex();
  background-color: var(--primary-color);
  height: 60px;
  width: 100%;
  p {
    @include para($fw: 600);
    color: var(--primary-text);
    text-align: center;
    letter-spacing: 1px;
  }
  .all-links {
    a {
      @include para($fw: 600);
      color: var(--primary-text);
      text-decoration: underline;
      margin: 0 5px;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    // p {
    //   font-size: 1.2rem;
    //   font-weight: 400;
    // }
  }
}
